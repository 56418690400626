






















































































import { Component, Ref, Vue } from 'vue-property-decorator'
import BotaoDeDrawer from '@/components/ui/BotaoDeDrawer.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { Loja, TurnoDeVenda } from '@/models'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import IconesDaToolbarPadroes from '@/components/layout/IconesDaToolbarPadroes.vue'

@Component({
	components: {
		BotaoDeDrawer,
		Confirmacao,
		IconesDaToolbarPadroes,
		SeletorDeLojas,
	},
})
export default class ToolbarDeVendas extends Vue  {
	@Ref() botaoDeDevolucao?: { $el: HTMLButtonElement }
	@Ref() botaoDeFechamentoDeCaixa?: { $el: HTMLButtonElement }
	@Ref() botaoDeMovimentarCaixa?: { $el: HTMLButtonElement }
	@Ref() confirmacaoDeDescartarVenda!: Confirmacao

	get loja() {
		return VendaModule.lojaDaVenda
	}

	set loja(loja: Loja | null) {
		VendaModule.setLojaDaVenda(loja)
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	set turnoDeVenda(turno: TurnoDeVenda | null) {
		VendaModule.setTurnoDeVenda(turno)
	}

	async mostrarDevolucao() {
		if (!await VendaModule.autenticarAcao({ regra: 'pode-fazer-devolucao' })) return
		await this.irParaDialogoDeDevolucao()
	}

	async irParaDialogoDeDevolucao() {
		const loja = VendaModule.lojaDaVenda
		if (!loja)
			return AlertModule.setError('Nenhuma loja selecionada para vender')
		if(!loja.seriesFiscais.some(serie => serie.tipo === "Devolução"))
			return AlertModule.setError('Loja não possui série fiscal de devolução')
		if (VendaModule.temVendaEmAndamento && !await this.confirmacaoDeDescartarVenda.mostrar()) return
		this.$router
			.push({
				name: this.$route.name as string,
				query: { devolucao: 'true' },
			})
			.catch()
	}

	async mostrarFechamento() {
		if(await VendaModule.moverParaMovimentacoesCasoNaoTenhaAnexo()) return
		if (VendaModule.temVendaEmAndamento && !await this.confirmacaoDeDescartarVenda.mostrar()) return
		this.$router
			.push({
				name: this.$route.name as string,
				query: { closing: null },
			})
			.catch()
	}
	mostrarMovimentacao() {
		this.$router
			.push({
				name: this.$route.name as string,
				query: { ['cash-handling']: null },
			})
			.catch()
	}

	created() {
		document.addEventListener('keydown', this.atalhoDeDevolucao)
		document.addEventListener('keydown', this.atalhoDeFecharCaixa)
		document.addEventListener('keydown', this.atalhoDeMovimentarCaixa)
	}
	destroyed() {
		document.removeEventListener('keydown', this.atalhoDeDevolucao)
		document.removeEventListener('keydown', this.atalhoDeFecharCaixa)
		document.removeEventListener('keydown', this.atalhoDeMovimentarCaixa)
	}
	atalhoDeDevolucao(event: KeyboardEvent) {
		if (!event.altKey || event.key.toLowerCase() !== 't') return
		if (!this.botaoDeDevolucao) return
		event.preventDefault()
		this.botaoDeDevolucao.$el.click()
	}
	atalhoDeFecharCaixa(event: KeyboardEvent) {
		if (!event.altKey || event.key.toLowerCase() !== 'a') return
		if (!this.botaoDeFechamentoDeCaixa) return
		event.preventDefault()
		this.botaoDeFechamentoDeCaixa.$el.click()
	}
	atalhoDeMovimentarCaixa(event: KeyboardEvent) {
		if (!event.altKey || event.key.toLowerCase() !== 'm') return
		if (!this.botaoDeMovimentarCaixa) return
		event.preventDefault()
		this.botaoDeMovimentarCaixa.$el.click()
	}
	get temVendaComPagamentos() {
		return VendaModule.temVendaComPagamentos
	}
}
